#tables {
    width: 100%;
    overflow-x: auto;
    table {  
        min-width: 960px; 
        width: 100%; 
        thead {
            background-color: $medBlue;
            color: $white;
            font-size: 16px;
            font-weight: 600;
            font-family: $secondary-font;
            border: none;
            th {
                padding: 25px 0px;
                text-align: center;
            }
            tr {
                th:first-child {
                    padding-left: 20px!important; 
                }
                th:last-child {
                    padding-right: 20px!important; 
                }
            }
        }
        tbody {
            color: $grey6;
            font-size: 16px;
            font-weight: 400;
            font-family: $secondary-font; 
            border: none;
            tr {
                td:first-child {
                    padding-left: 20px!important; 
                }
                td:last-child {
                    padding-right: 20px!important; 
                }
            }
            tr:nth-of-type(odd) {
                background-color: $tableGrey;
                
            }
            tr:nth-of-type(even) {
                background-color: $greyE;
            }
            td {
                padding: 25px 0px;
                text-align: center;
            }
        }
    }
}
