#seriesStandings { 

    #pointsSystem {
        margin-top: 80px;  
        margin-bottom: 100px; 
        
        @include mobile {
            margin-bottom: 40px;
        }
        @include small-mobile {
            margin-bottom: 40px;
        }
    }  

    #description {
        @extend #intro;
        text-align: left; 
        padding-top: 75px;
        padding-bottom: 25px;
        padding-left: 0px;
        padding-right: 0px; 
        
        ul {
            list-style: disc;
            margin: 35px 0 20px 0;
            padding-left: 20px;
        }
        li {
            @extend p;
            font-size: 20px;
            line-height: 1.2em;
            margin: 8px 0;
        }
    } 

    #standingLinks {
        margin-bottom: 100px; 
        a {
            background-color: $greyE; 
            width: 162px;
            padding: 14px 26px;
            margin: 10px 20px 10px 0; 
            display: inline-block; 
            h4 {
                float: left;
            }
            img {
                width: 25px; 
                float: right; 
                margin-top: 5px;
                transition: $transition;
            }
            &:hover {
                img {
                    transform: translateX(4px);
                }
            }
        }
    }

}