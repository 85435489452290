#exploreNav {
        width: 100%;
        text-align: center;

        ul {
            background: $medBlue;
            padding: 12px;
        }
        li {
            display: block;
            margin: 0 15px;
        }
        h4 { 
            font-size: 18px; 
            color: $white;
            font-weight: 400;
            letter-spacing: .05em;
            text-transform: uppercase;
            margin: 15px 0;
            transition: $transition;
            
            &:hover {
                opacity: .4;
            }
        }
        #toggle {
            background: $greyE;
            padding: 14px 0;
            cursor: pointer;
            color: $grey6;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: .05em;
            text-transform: uppercase;
            
            a {
                color: $grey6;
                &:hover {
                    color: $grey6;
                }
            }
        }
    }