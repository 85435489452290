#register {

    #series {
        background-color: $medBlue; 
        text-align: center; 
        padding: 80px 10px 100px 10px;
        //padding-top: 90px; 
        //padding-bottom: 110px;
        h1, h3 {
            color: $white;   
        }
        h1 {
            margin-bottom: 20px;
        }
        h3 {
            font-size: 25px;
            font-family: $secondary-font; 
            font-weight: 300;
            margin-bottom: 45px;
        }
    }
    .registerImage {   
        height: 315px; 
        width: 100%;
        background-size: cover;
        background-position: center;   
        background-repeat: no-repeat; 
        
        @include mobile {
            margin-bottom: 30px;
        }
        @include small-mobile {
            margin-bottom: 30px;
        }
    }
    #banner {
        #bannerImage {
            height: 500px; 
            width: 100%;
            background-size: cover; 
            background-position: center;
            background-repeat: no-repeat;   
        }
    }
    #greyBg {
        background-color: $greyE;
        padding: 60px 0 60px 0;
        
        @include mobile {
            padding: 30px 0 60px 0;
        }
        @include small-mobile {
            padding: 30px 0 60px 0;
        }
    }
    //#race, #funRun {
    //    padding: 90px 0px; 
    //}
    #race {
        border-bottom: 1px solid $greyC;
        padding-bottom: 50px;
        margin-bottom: 50px;
        
        @include mobile {
            border: none;
            padding-bottom: 30px;
            margin-bottom: 30px;
        }
        @include small-mobile {
            border: none;
            padding-bottom: 30px;
            margin-bottom: 30px;
        }
    }
    #family {
        padding-top: 90px; 
        padding-bottom: 110px;
        text-align: center; 
        img {
            width: 104px; 
        }
        h1 {
            margin-bottom: 20px;
            margin-top: 20px;
        }
        h3 {
            font-size: 25px;
            font-family: $secondary-font; 
            font-weight: 300;
            margin-bottom: 45px;
            color: $grey6; 
            max-width: 75%;
            margin: 0 auto 45px auto;
            
            @include mobile {
                max-width: 85%;
            }
            @include small-mobile {
                max-width: 100%;
            }
        }
    }

}