
// Form validation message styling 
#formValidationMessages {
    position: relative; 
    margin-bottom: 50px; 
    p { 
        font-style: italic; 
        font-family: $primary-font; 
        font-size: 22px; 
    }
    #thanks { 
        display: none; 
        position: absolute;   
        margin-top: 20px; 
    }   
    #errorMessage {
        display: none; 
        position: absolute; 
        margin-top: 20px; 
    }        
}

// Add red border around text inputs if parsley validation returns errors. 
input.parsley-error,   
input.fui-error,
#sponsorOpportunities form .nice-select.parsley-error,     
textarea.parsley-error {
    border-color: $red !important; 
    border-style: solid !important;
    background-color: $white;       
    border-width: 2px;
} 
.fui-limit-text, .fui-error-message {
    display: none;
}
.parsley-required {
    display: none;
}
     
     



