#contact {
    #intro {
        a {
            font-weight: 400;
            text-transform: none; 
            font-family: $secondary-font; 
        }
    }
    
    .formContent { 
        max-width: 50%;
        margin: 0 auto;
        
        @include tablet {
            max-width: 60%;
        }
        @include mobile {
            max-width: 80%;
        }
        @include small-mobile {
            max-width: 90%;
        }
    } 

}