#raceLanding {
    margin-bottom: 50px;
    
    #bannerSection {
        height: 450px;
        //position: relative;
        margin-bottom: 80px;
        .container {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
        }
        #logo {
            width: 250px;
            height: 250px;
            border-radius: 50%; 
            padding: 15px;   
            position: absolute;
            bottom: -120px;
            right: 0;
            background-color: $white; 
            box-shadow: $shadow;
            
            @include mobile {
                width: 200px;
                height: 200px;
                right: 20px;
                bottom: 30px;
            }
            @include small-mobile {
                width: 200px;
                height: 200px;
                right: 20px;
                bottom: 30px;
            }
        }
        .button {
            border: 3px solid $white;
            position: absolute;
            left: 0;
            bottom: -26px;
            
            &:hover {
                background: $darkBlue;
            }
            
            @include mobile {
                left: 20px;
            }
            @include small-mobile {
                left: 20px;
            }
        }
    }
    #details {
        margin-bottom: 50px;
        h2 {
            font-weight: 600;
            margin-bottom: 10px;
        }
        h4 {
            color: $yellow;
            font-size: 20px;
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 10px;
            margin-top: 30px;
        }
        .dates {
            p {
                margin-bottom: 6px;
                
                span {
                    color: $yellow;
                    font-weight: 500;
                    margin: 0 5px;
                }
            }
            .links {
                p {
                    transition: $transition;
                    &:hover {
                        color: $medBlue;
                    }
                }
            }
        }
        .intro {
            margin-top: 35px;
        }
    }
    #highlights {
        margin: 20px 0 50px 0;
        .highlightCard {
            border: 1px solid $greyC;
            margin-top: 20px;
            
            .featureImage {
                width: 100%;
                height: 230px; 
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
            }
            .content {
                padding: 30px;
                
                .location {
                    margin: 10px 0 15px 0;
                    font-weight: 500;
                }
            }
            .button {
                display: block!important;
                border-radius: 0!important;
            }
        }
    } 
    #registerBlock {
        margin: 50px 0;
        background: $medBlue;
        padding: 180px 0;
        text-align: center;
        
        @include tablet {
            padding: 120px 0;
        }
        @include mobile {
            padding: 100px 0;
        }
        @include small-mobile {
            padding: 80px 0;
        }
        
        h2 {
            color: $white;
        }
        p {
            color: $white;
            margin: 20px auto 45px auto;
            max-width: 60%;
            
            @include tablet {
                max-width: 75%;
            }
            @include mobile {
                max-width: 85%;
            }
            @include small-mobile {
                max-width: 100%;
            }
        }
    } 
    #registerDetails {
        margin: 50px 0 20px 0;
        
        .section {
            //border-bottom: 2px solid $yellow;
            margin-bottom: 30px;
            
            h2 {
                width: 30%;
                float: left;
                line-height: 1.3em;
                word-break: break-word;
                padding-right: 30px;
                
                @include tablet {
                    float: none;
                    width: 100%;
                    margin-bottom: 15px;
                }
                @include mobile {
                    float: none;
                    width: 100%;
                    margin-bottom: 15px;
                }
                @include small-mobile {
                    float: none;
                    width: 100%;
                    margin-bottom: 15px;
                }
            }
            .content {
                width: 70%;
                float: left;
                border-left: 2px solid $yellow;
                padding-left: 50px;
                
                @include tablet {
                    float: none;
                    width: 100%;
                    border: none;
                    padding-left: 0;
                }
                @include mobile {
                    float: none;
                    width: 100%;
                    border: none;
                    padding-left: 0;
                }
                @include small-mobile {
                    float: none;
                    width: 100%;
                    border: none;
                    padding-left: 0;
                }
                
                p {
                    text-align: left;
                    margin-bottom: 8px;
                    
                    @include mobile {
                        font-size: 22px;
                    }
                    @include small-mobile {
                        font-size: 20px;
                    }
                    a {
                        font-weight: 500;
                        
                        &:hover {
                            color: $yellow;
                        }
                    }
                    span {
                        color: $yellow;
                        font-weight: 500;
                        margin: 0 5px;
                    }
                }
            }
        }
    }
    #course {
        margin: 60px 0 30px 0;
        position: relative;
        padding-top: 60px;
        border-top: 1px solid $greyD;
        max-width: 100%;
        overflow-x: hidden;
        
        @include tablet {
            margin: 30px 0 30px 0;
        }
        @include mobile {
            margin: 30px 0 20px 0;
        }
        @include small-mobile {
            margin: 30px 0 20px 0;
        }
        
        .hideme {
            background: $white;
            width: 100%;
            height: 55px;
            position: absolute;
            top: 50px;
        }
        #mapmyfitness_route {
            margin-top: 0px;
        }
        a {
            color: $white;
        }
        div {
            color: $white;
        }
    }
    #gallery {
        margin: 50px 0;
        background: $medBlue;
        padding: 80px 0 50px 0;
        
        .flickity-page-dots {
            display: none; 
        }
        .pressKit {
            display: block;
            margin: 50px auto 0 auto;
            text-align: center;
            img {
                display: inline-block;
                max-width: 25px;
                margin-right: 6px;
                transition: $transition;
            }
            h4 {
                color: $yellow;
                font-size: 20px;
                font-weight: 600;
                text-transform: uppercase;
                text-align: left;
                display: inline-block;
                position: relative;
                top: 3px;
            }
            &:hover {
                img {
                    transform: translateY(2px);
                }
            }
        }
    }
    #benefits {
        margin: 50px 0;
        p {
            padding-right: 30px;
            margin-bottom: 20px;
        }
        img {
            width: 100%; 
            border: 1px solid $greyD;  
            margin-bottom: 30px;
            transition: $transition;
            
            &:hover {
                border-color: $yellow;
            }
        }
    }
    #courseRecords {
        margin: 0 0 50px 0;
        
        h3 {
            background: $medBlue;
            color: $white;
            margin: 30px 0 0 0;
            text-align: center;
            letter-spacing: .05em;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 22px;
            padding: 10px 0;
        }
        #tables {
            table {
                min-width: 300px;
                thead {
                    background-color: $greyD;
                    color: $medBlue;
                    
                    th {
                        padding: 12px 0 13px 0;
                    }
                }
            }
        }
    }
    #results {
        margin: 80px 0 50px 0;
        p {
            margin-bottom: 20px;
        }
        a {
            background-color: $greyE; 
            width: 190px;
            padding: 14px 26px;
            margin: 10px 20px 10px 0; 
            display: inline-block; 
            h4 {
                float: left;
            }
            img {
                width: 27px; 
                float: right; 
                margin-top: 5px;
                border: none;
                transition: $transition;
            }
            &:hover {
                img {
                    transform: translateX(4px);
                }
            }
        }
    }
    p {
        a {
            &:hover {
                color: $medBlue;
            }
        }
    }
}