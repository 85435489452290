#seriesNav {
    ul {
        padding: 18px 15px 18px 15px; 
        background-color: $greyE;
        margin-bottom: 0px;

        li { 
            display: inline-block;   
            h4 {
                color: $grey6;
                text-transform: uppercase;
                font-size: 18px; 
                transition: $transition;
                &:hover { 
                    color: $medBlue;
                }
            }
        }
    } 
 
    #desktopNav { 
        width: 100%;
        height: 50px; 
        text-align: center;
        ul { 
            width: 100%;
            display: inline-block;
            li {
                margin-right: 5%;  
            }
        }
    }

    #mobileNav {
        bottom: -10px; 
        overflow: hidden;  
        background-color: $greyE;
        width: 100%; 
        #wrapper { 
            position: relative; 
            width: 1730px;
            left: -865px; 
            text-align: center;   
            ul {
                width: 1000px; 
                float: left; 
                left: 560px; 
                li {
                    margin-right: 25px; 
                }
            }
            .clear {
                clear: both;
            }
        }
    }

}

// ACTIVE LINKING 
#seriesHistory {
    #seriesNav {
        .series {
            color: $medBlue;  
        }
    } 
}
#seriesRules {
    #seriesNav {
        .rules {
            color: $medBlue;  
        }
    }  
} 
#seriesAwards {
    #seriesNav {
        .awards {
            color: $medBlue;  
        }
    }    
}
#seriesVolunteer {
    #seriesNav {
        .volunteer {
            color: $medBlue;  
        }
    }    
}
#seriesStandings {
    #seriesNav {
        .standings {
            color: $medBlue;  
        }
    }   
}


