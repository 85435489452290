#alert {
    background: $darkBlue;
    width: 100%;
    padding: 15px;
    transition: $transition;
    
    &:hover {
        background: #020F27;
    }
    
    h2 {
        color: $white;
        font-weight: 400;
        font-size: 18px;
        text-align: center;
        letter-spacing: .03em;
        
        span {
            font-weight: 600;
            text-transform: uppercase;
            color: $yellow;
        }
    }
}