.raceCard { 
    border: 1px solid $greyC;
    position: relative;
    margin-bottom: 50px;
    
    @include tablet {
        margin-bottom: 40px;
    }
    @include mobile {
        margin-bottom: 30px;
    }
    @include small-mobile {
        margin-bottom: 20px;
    }
    .retired {
        position: absolute;
        top: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.7);
        padding: 8px 20px;
        h5 {
            color: $white;
            font-size: 16px;
            text-transform: uppercase;
            letter-spacing: .08em;
            font-weight: 600;
        }
    }
    .featureImage { 
        height: 230px; 
        width: 100%;
        background-size: cover;
        background-position: center;   
        background-repeat: no-repeat;     
    }
    .content {
        padding: 30px; 
        h2 {
            font-size: 30px;
            line-height: 1.2em;
            margin-top: -10px;
            margin-bottom: 12px;
        }
        h4 {
            color: $grey6; 
            font-family: $secondary-font; 
            font-weight: 400;
            
            @include mobile {
                font-size: 24px;
            }
            @include small-mobile {
                font-size: 22px;
            }
            
            span {
                color: $yellow;
                margin: 0 4px;
            }
        } 
        p {
            font-size: 25px;
            margin-top: 15px;
        }
    }
    .learnMore {
        background-color: $medBlue; 
        height: 54px;
        transition: $transition;
        h3 {
            color: $white; 
            font-size: 20px;
            text-transform: uppercase; 
            letter-spacing: 1.8px;
            font-weight: 500;
            position: absolute;
            right: 77px;
            bottom: 12px;
        }
        img {
            width: 27px;
            position: absolute;
            right: 41px;
            bottom: 14px;
            transition: $transition;
        }
    }
    &:hover {
        .learnMore {
            background-color: $darkBlue; 
            img {
                transform: translateX(5px);
            }
        }
                        
    }
}