#error {
    background: url(https://bucks5k.herokuapp.com/assets/img/error.jpg);
    background-position: bottom;
    background-size: cover;
    height: 100vh;
    
    .content {
        position: absolute;
        width: 90%;
        bottom: 10%;
        left: 0;
        right: 0;
        margin: 0 auto;
        
        @include mobile {
            bottom: 5%;
        }
        @include small-mobile {
            bottom: 5%;
        }
        
        h4 {
            font-family: $secondary-font;
            font-size: 30px;
            color: $white;
            font-weight: 600;
            opacity: .5;
            text-align: left;
            letter-spacing: .08em;
            margin-bottom: 8px;
            
            @include mobile {
                font-size: 24px;
            }
            @include small-mobile {
                font-size: 22px;
            }
        }
        h2 {
            max-width: 35%;
            font-family: $secondary-font;
            font-size: 40px;
            color: $white;
            font-weight: 300;
            text-align: left;
            letter-spacing: .03em;
            line-height: 1.2em;
            
            @include small-desktop {
                max-width: 40%;
            }
            @include tablet {
                max-width: 50%;
            }
            @include mobile {
                max-width: 85%;
                margin-bottom: 30px;
                font-size: 34px;
                letter-spacing: .02em;
            }
            @include small-mobile {
                max-width: 95%;
                margin-bottom: 30px;
                font-size: 28px;
                letter-spacing: .02em;
            }
        }
        .button {
            position: absolute;
            right: 0;
            bottom: 50px;
            background: rgba(255, 255, 255, 0.12);
            
            &:hover {
                background: rgba(255, 255, 255, 0.22);
            }
            
            @include mobile {
                position: relative;
                bottom: 0;
                left: 0;
            }
            @include small-mobile {
                position: relative;
                bottom: 0;
                left: 0;
            }
            
            a {
                color: $white;
                
                &:hover {
                    color: $white;
                }
            }
        }
    }
}