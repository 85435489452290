#racesLandingNav {
    display: none; 
    ul {
        padding: 18px 15px 18px 15px; 
        background-color: $greyE;
        li {
            display: inline-block;   
            h4 {
                color: $grey6;
                text-transform: uppercase;
                font-size: 18px; 
                transition: $transition;
                &:hover { 
                    color: $medBlue;
                }
            }
        }
    }
 

    #desktopNav { 
        width: 100%;
        height: 50px; 
        position: fixed; 
        bottom: 25px;
        text-align: center;
        z-index: 990;
        ul {
            width: 70%;
            border: 3px solid $white;
            border-radius: 30px;
            padding: 10px 15px 10px 15px;
            text-align: center;
            display: inline-block;
            @include tablet { 
                width: 90%;  
            } 
            li {
                margin-right: 5%;  
            }
        }
    }

    #mobileNav {
        position: fixed; 
        bottom: -10px; 
        overflow: hidden;  
        width: 100%; 
        background-color: $greyE;
        #wrapper { 
            position: relative; 
            width: 1730px;
            left: -865px; 
            text-align: center;   
            ul {
                width: 1000px; 
                float: left; 
                left: 560px; 
                li {
                    margin-right: 25px; 
                }
            }
            .clear {
                clear: both;
            }
        }
    }
}