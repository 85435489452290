.newsCard {
    width: 100%;
    background-color: $greyE; 
    
    @include tablet {
        margin-bottom: 20px;
    }
    @include mobile {
        margin-bottom: 20px;
    }
    @include small-mobile {
        margin-bottom: 20px;
    }
    .featureImage { 
        width: 100%;   
        height: 180px;
        background-size: cover;
        background-position: center;   
        background-repeat: no-repeat; 
    }
    .content {
        padding: 30px; 
    }
    h4 {
        color: $grey9; 
        font-size: 14px; 
        font-family: $secondary-font;
        font-weight: 600;
    }
    h2 {
        font-size: 25px; 
        font-weight: 500;
        margin-top: 5px;
        line-height: 1.2em;
    }
    p {
        font-size: 20px; 
        font-weight: 300;
        margin-top: 10px;
        margin-bottom: 15px; 
    }
    .button {
        font-size: 16px; 
    }
}