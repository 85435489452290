#races {
    padding-bottom: 30px;
    
    #intro {
        h3 {
            max-width: 80%;
            
            @include tablet {
                max-width: 85%;
            }
            @include mobile {
                max-width: 90%;
            }
            @include small-mobile {
                max-width: 95%;
            }
        }
    }
    
    .archives {
        text-align: center;
        //padding-bottom: 30px;
        text-transform: uppercase;
        letter-spacing: .05em;
        color: $grey9;
    }
      
    #grid {
        margin-bottom: 60px;  
        #primaryCTA { 
            background-color: $medBlue;  
            padding-top: 170px; 
            padding-bottom: 200px; 
            text-align: center; 
            img {
                width: 128px; 
            }
            h3 {
                color: $white; 
                font-size: 42px;
                margin-top: 5px;
                margin-bottom: 44px;
            }
            a {
                margin: 0 auto;
            }
        }
    }

}