.button {
	font-family: $primary-font; 
	font-size: 18px;
    font-weight: 500;
	text-transform: uppercase; 
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    border-radius: 50px;
    outline: none;
    letter-spacing: .04em;  
    transition: $transition;
    cursor: pointer; 
    &.yellow {
        background-color: $yellow;
        border: 2px solid $yellow;
        color: $white;
        width: 200px;
        &:hover {
            background-color: $medBlue;
            border: 2px solid $yellow;
            color: $white;
        }
    }
    &.darkBg {
        @extend .yellow;
        &:hover {
            background-color: transparent;
        }
    }
    &.blue {
        background-color: $medBlue;
        border: 2px solid $medBlue;
        color: $white;
        &:hover {
            background-color: $yellow;
            border: 2px solid $yellow;
            color: $white;
        }
    }
    &.disabled {
        background-color: $darkBlue; 
        border: 2px solid $darkBlue;
        color: $white;
        cursor: auto; 
    }
    &.noBackground {
        padding: 0px;
        color: $medBlue; 
        font-weight: 600; 
        font-size: 22px; 
        &:hover {
            color: $yellow;
        }
    }
    &.nextToInput {
        width: 140px; 
        background-color: $yellow; 
        border: 2px solid $yellow;
        color: $white;
        padding: 10px 15px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px; 
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px; 
        position: relative;
        
        @include mobile {
            width: 120px; 
        }
        @include small-mobile {
            width: 120px; 
        }
        &:hover {
            background-color: $darkBlue;
            border: 2px solid $darkBlue;
            color: $white;
        }  
    }
}


