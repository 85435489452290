input[type=text],   
input[type=password], 
input[type=search],
input[type=email],     
input[type=phone],
input[type=tel] {   
    font-family: $secondary-font;      
    border: none; 
    border-radius: 25px; 
    margin-bottom: 20px;
    color: $grey6; 
    font-weight: 300;   
    font-size: 18px; 
    padding: 0 25px; 
    height: 50px;
    width: 100%; 
    outline: none; 
    border: 2px solid $white;
    transition: $transition;
    
    &:hover { 
        border-color: #E5E5E5;
    }
    &:active, &:focus {
        border-color: #DDD;
    }
}


 //////////////
 //  RADIOS 
 //////////////
.radios {
    label {
        span {
            text-transform: none; 
            font-size: 18px; 
            color: $grey6; 
            font-family: $secondary-font; 
            font-weight: 300;
            margin-top: 6px;
        }
    }
    .box {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 0px;
        margin-top: 5px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default radio button */
    .box input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
        position: absolute;
        top: 0;
        left: 6px;
        height: 24px;
        width: 24px;
        background-color: $white;
        border-radius: 50%;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .box input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the indicator (dot/circle) */
    .box .checkmark:after {
        top: 4px;
        left: 4px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: $medBlue;
    }
}