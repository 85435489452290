#volunteer {
    background-color: $medBlue; 
    padding: 100px 0px;   
    text-align: center; 
    img {
       width: 100px;   
    }
    h2 { 
        color: $white; 
        font-size: 40px;  
        font-weight: 400;
        margin-top: 20px;
        @include mobile { 
            font-size: 30px;
        }
        @include small-mobile { 
            font-size: 30px;
        }
    } 
    h1 {
        color: $white;
        font-weight: 600;
        margin-top: -10px;
        margin-bottom: 20px;
        @include mobile { 
            font-size: 30px;
        }
        @include small-mobile { 
            font-size: 30px;
        }
    }
    .button {
        margin: 0 auto;
    }
} 