#gallery {
    #galleries {
        margin-bottom: 50px;
        
        .galleryCard {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            width: 100%;
            height: 300px;
            position: relative;
            margin-bottom: 30px;
            
            h3 {
                text-align: center;
                color: $white;
                font-weight: 600;
                letter-spacing: .05em;
                text-transform: uppercase;
                font-size: 18px;
                line-height: 1.1em;
                background-color:rgba(20, 53, 78, 0.9); 
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                padding: 20px 0;
                transition: $transition;
            }
            
            &:hover {
                h3 {
                    color: $yellow;
                }
            }
        }
    }
}
#galleryPost {
    #pageContent {
        padding: 40px 0;
        
        .introSection {
            margin-bottom: 40px;
            
            h1 {
                margin-bottom: 30px;
            }
        }
        .galleryBlock {
            max-width: 60%;
            margin: 40px auto 0 auto;
            
            @include desktop {
                max-width: 70%;
            }
            @include small-desktop {
                max-width: 75%;
            }
            @include tablet {
                max-width: 85%;
            }
            @include mobile {
                max-width: 100%;
            }
            @include small-mobile {
                max-width: 100%;
            }
            
            .imageBlock {
                margin-bottom: 40px;
                
                p {
                    font-size: 22px;
                    text-align: center;
                }
            }
        }
    }
}