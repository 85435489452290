#nav { 
    background-color: $medBlue; 
    padding-top: 27px;
    h5 {
        transition: $transition;
        &:hover { 
            opacity: .4;
            color: $white!important;
        }
    }
    .hovered {
        opacity: .4;
        color: $white!important;  
    }
    .socialIcons {
        position: absolute;     
        top: 25px;
        right: 15px;   
    } 
    #desktopNav { 
        height: 175px;   
        .navLogo {
            position: relative;
            transition: $transition;
            @include small-desktop {
                margin-top: 10px;
            }
        }
        .menuItems {
            position: absolute;
            top: 78px;
            right: 15px; 
            .firstLevel {
                display: inline-block; 
                margin-left: 35px;  
                cursor: pointer;
                &:first-of-type {
                    margin-left: 0;
                }
                > h5 {
                    padding-bottom: 25px;
                } 
                .dropdown {
                    z-index: 990; 
                    background-color: $darkBlue; 
                    box-shadow: $shadow;
                    text-align: center;
                    margin-left: -84px;
                    div {
                        height: 24px;
                        width: 24px; 
                        background-color: $darkBlue; 
                        margin: -12px auto 0 auto;
                        -ms-transform: rotate(45deg); /* IE 9 */
                        -webkit-transform: rotate(45deg); /* Safari */
                        transform: rotate(45deg);
                    }
                    li:first-of-type {
                        margin-top: 20px; 
                    }
                    li:last-of-type {
                        margin-bottom: 40px; 
                    }
                    li {
                        margin: 12px 70px;
                    }
                }
                .collapse.in {
                    position: absolute; 
                }
                .collapsing {
                    position: absolute;  
                }
                .sponsors {
                    margin-left: -100px;
                }
            }
        }
        #annualSeries {
            position: absolute;     
            top: 20px;
            right: 145px;
            text-transform: uppercase;
            
            h5 {
                font-size: 25px; 
                font-weight: 600; 
                letter-spacing: .08em;
                
                &:hover { 
                    opacity: 1;
                }
                    
                .year {
                    color: $yellow;
                    opacity: 1;
                }
                .series {
                    color: $white; 
                    opacity: 0.4; 
                }
            } 
        }
    }
    #mobileNav { 
        height: 140px; 
        padding-bottom: 20px; 
        .navLogo {
            width: 215px;  
            position: relative;
            top: 4px;
            @include small-mobile {
                top: 12px;
            }
        }
        .socialIcons { 
            @include mobile {
                top: 0;
                right: 10px;
                
                img {
                    width: 30px;
                }
            }
            @include small-mobile { 
                display:none;
                // top: 0;
                // right: 10px;
                
                img {
                    width: 30px;
                }
            }
        }
        #menuOpen {
            cursor: pointer; 
            width: 35px; 
            position: absolute; 
            top: 40%; 
            right: 15px;
            
            @include mobile {
                right: 15px;
            }
            @include small-mobile {
                right: 15px;
            }
        }
        #mobileMenu {
            height: 100vh; 
            width: 100vw;
            top: 0;
            background-color: $medBlue; 
            overflow-y: scroll;
            z-index: 990; 
            position: fixed;
            display: none;
            #menuClose {
                width: 25px; 
                position: absolute;
                top: 34px; 
                right: 34px; 
                cursor: pointer; 
            }
            .menuItems {
                margin-top: 100px; 
                margin-bottom: 125px;
                .collapse {
                    background-color: $darkBlue; 
                    width: 100%;
                    padding: 50px 0px 30px 0px;
                    margin-bottom: 25px; 
                }
                .collapsing {
                    background-color: $darkBlue; 
                    padding: 50px 0px 30px 0px;
                    margin-bottom: 25px; 
                }
            }
            h5 {
                text-align: center; 
                margin-bottom: 25px;
            }
            .socialIcons {
                position: relative;
                top: auto; 
                bottom: 50px;
                left: 0;
                right: 0;
                text-align: center;
            }
        }
    }
}

.nav-visited { 
    color: $yellow !important; 
}

