#subscribe {
    .backgroundImage {
        background-size: cover;
        background-repeat: no-repeat; 
        background-position: center; 
        width: 100%;   
        padding: 180px 0;  
        
        @include mobile {
            padding: 80px 0;
        }
        @include small-mobile {
            padding: 0 0;
        }
        #content {
            max-width: 40%;
            margin: 0 auto;
            padding: 60px; 
            background-color:rgba(20, 53, 78, 0.9); 
            text-align: center; 
            
            @include desktop {
                max-width: 50%;
            }
            @include small-desktop {
                max-width: 50%;
            }
            @include tablet {
                max-width: 75%;
            }
            @include mobile {
                max-width: 85%;
                padding: 50px; 
            }
            @include small-mobile {
                max-width: 100%;
                padding: 40px; 
            }
            
            h2 {
                color: $white;
                font-size: 40px;  
                font-weight: 400;
                line-height: 1.1em;
                margin-bottom: 20px;
                
                span {
                    font-weight: 600;
                    //margin-top: -10px;
                }
            }
            /*input {
                border-top-left-radius: 25px;
                border-top-right-radius: 0px;
                border-bottom-left-radius: 25px; 
                border-bottom-right-radius: 0px; 
                max-width: 40%;
                border: none;
                display: inline-block; 
                margin-right: -3px;
                padding-bottom: 1px;
                
                @include desktop {
                    max-width: 50%;
                }
                @include small-desktop {
                    max-width: 50%;
                }
                @include tablet {
                    max-width: 60%;
                }
                @include mobile {
                    max-width: 60%;
                }
                @include small-mobile {
                    max-width: 60%;
                }
            }
            .button {
                display: inline-block; 
            }*/
            .button {
                display: block;
                margin: 30px auto 0 auto;
                &:hover {
                    background: transparent;
                }
            }
        }
    }
}