#seriesRules {

    #intro {
        padding-top: 74px;
        padding-bottom: 74px; 
        padding-left: 0px; 
        padding-right: 0px; 
    }

    #alert {
        background-color: $greyE; 
        padding-top: 34px;
        padding-left: 34px; 
        padding-right: 34px; 
        padding-bottom: 54px; 
        margin-bottom: 90px; 
        img {
            width: 35px; 
            margin-bottom: 10px;
            margin-right: 10px;
        }
        h2 {
            color: $grey9;
            font-size: 25px; 
            font-family: $primary-font; 
            text-transform: uppercase; 
            font-weight: 500;
            display: inline;
        }
        .body {
            margin-top: 20px; 
        }
    }

    #pointsSystem {
        margin-bottom: 120px; 
        
        @include mobile {
            margin-top: 30px;
        }
        @include small-mobile {
            margin-top: 30px;
        }
        h2 {
            font-size: 40px; 
            font-weight: 600;
            margin-bottom: 20px; 
        }
        h3 {
            display: none; 
        }
        p {
            padding-right: 180px;
            margin-bottom: 30px;
            
            @include small-desktop {
                padding-right: 160px;
            }
            @include tablet {
                padding-right: 100px;
            }
            @include mobile {
                padding-right: 0;
            }
            @include small-mobile {
                padding-right: 0;
            }
        }
    } 
}