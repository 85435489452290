#standingsLanding {
     
    .seriesNav {
        ul {
            text-align: center; 
        }
    } 

    #intro {
        width: 90%;
        h3 {
            margin-bottom: 0px;  
            font-family: $secondary-font; 
        }
    }
    
    #legend {
        h5 {
            font-size: 18px;
            font-weight: 500;
            color: $greyC;
            letter-spacing: .08em;
            text-align: center;
            text-transform: uppercase;
            margin: 0 0 20px 0;
        }
        ul {
            max-width: 500px;
            margin: -10px auto 80px auto;
            text-align: left;
            
            @include mobile {
                max-width: 80%;
            }
            @include small-mobile {
                max-width: 80%;
            }
            li {
                border-top: 1px solid $greyD;
                padding: 15px 10px;
                h3 {
                    font-size: 22px;
                    color: $grey6;
                    line-height: 1.2em;
                    font-weight: 300;
                    
                    span {
                        color: $grey9;
                        font-style: italic;
                    }
                }
            }
        }   
    }

    #tables {
        margin-bottom: 100px; 
        h3 {
            font-size: 20px;
            font-weight: 600;
            letter-spacing: .04em;
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 20px; 
            
            @include mobile {
                text-align: left;
            }
            @include small-mobile {
                text-align: left;
            }
        }
        table {
            margin-bottom: 50px;
        }
    }

}