html,body {
    min-height:100%;
    height:100%;
    &.modal-active {
        overflow: hidden;
    } 
} 

.modal-container { 
    display: none; 
    background-color: rgba(20, 53, 78, 0.90);
    position: fixed;
    height: 100vh; 
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 998;
    .x {
        width: 24px; 
        position: absolute; 
        top: 30px;
        right: 30px;
        cursor: pointer; 
        transition: $transition;
        
        &:hover {
            opacity: .5;
        }
    }
    .content {
        max-width: 600px; 
        margin: 0 auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        
        @include mobile {
            padding: 0 30px;
        }
        @include small-mobile {
            padding: 0 30px;
        }
        h1 { // title/headlines
            color: $white; 
            text-transform: none;
        }
        h5 { // Uppercase
            color: $white !important; 
            font-size: 20px !important; 
            font-weight: 500 !important;
        }
        p { // body 
            color: $white; 
        }
        .copySuccess { 
            display: none; 
        }
        a {
            &:hover {
                color: $yellow; 
            }
        }
        input {
            background-color: transparent;
            border: 2px solid $grey6;
            outline: none;
            font-family: $primary-font; 
            font-size: 25px; 
            font-weight: 400; 
            color: $white; 
        }
        .share-list {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 35px;
            a {
                cursor: pointer;
            }
            img {
                float: left;
                border-style: solid;
                border-width: 4px;
                border-radius: 50%;
                border-color: $white;
                margin: 0 12px;
                max-width: 60px;
            }
        }
    }
} // End modal-container


#shareModal {
    h1, h3, p {
        text-align: center; 
    }
    h3 {
        color: $yellow; 
        font-size: 20px; 
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .08em;
    }
    p {
        font-size: 20px;
        font-style: italic;
        font-weight: 500;
        margin-top: 30px;
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
    }
    .share-list {
        img {
            border-style: none;
        }
    }
    .success-message {
        display: none; 
    }
} // End shareModal