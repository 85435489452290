#sponsorsBlock {
    margin-top: 20px; 
    padding-bottom: 100px;
    h1 {
        font-weight: 600;
        text-align: center;   
        margin-top: 50px;
        margin-bottom: 30px;   
    }  
    img {
        width: 100%; 
        border: 1px solid $greyD;   
        margin-bottom: 30px;
        transition: $transition;
        
        &:hover {
            border: 1px solid $yellow;
        }
        
        @include tablet {
            margin-bottom: 20px;
        }
        @include mobile {
            margin-bottom: 20px;
        }
        @include small-mobile {
            margin-bottom: 20px;
        }
    }
    .center {
        img {
            display: block;
            margin: 0 auto;
            width: 25%;
            
            @include small-desktop {
                width: 35%;
            }
            @include tablet {
                width: 50%;
            }
            @include mobile {
                width: 100%;
            }
            @include small-mobile {
                width: 100%;
            }
        }
    }
}