/*---------------
  GLOBAL
---------------*/
body {
    margin: 0;
    padding: 0; 
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
img {
    border: 0;
    max-width: 100%;
}
a {
    text-decoration: none;
    outline: none;
    transition: $transition;
}
button {
    text-decoration: none;
    outline: none;
}
.contain {
	position: relative;
}
.backgroundSettings {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    
    @include small-desktop {
        background-attachment: scroll;
    }
    @include tablet {
        background-attachment: scroll;
    }
    @include mobile {
        background-attachment: scroll;
    }
    @include small-mobile {
        background-attachment: scroll;
    }
}
.overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.35);
}
.clear {
    clear: both;
}
::-moz-selection {
    background: $medBlue;
	color: $white;
}
::selection {
    background: $medBlue;
	color: $white;
}
// Styles the register links on the nav, dropdown, and footer
.register {
    color: $yellow !important; 
    font-weight: 500 !important;   
}
.noPad {
    padding-left: 0px; 
    padding-right: 0px; 
    margin-left: 0;
    margin-right: 0;
}
.row {
    @include small-mobile {
        padding: 0 15px;
    }
}