#seriesHistory {
 
    #intro {
        padding-top: 100px 0; 
        h2 { 
            margin: 0 auto;
        }
    }
    
    #history {
        background-color: $medBlue; 
        position: relative;
        
        h2 {
            color: $white; 
            margin-top: 0px; 
            margin-bottom: 25px; 
            font-size: 40px;
        }
        p {
            color: $white; 
            font-size: 22px;
            margin-bottom: 40px;
        }
        .button {
            width: 220px;
            display: block;
            margin: 30px 0;
        }
        .image {
            height: 100%; 
            width: 50%; 
            position: absolute;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center; 
            @include tablet {
                width: 100%; 
                height: 300px; 
                position: relative; 
                margin-bottom: 20px;
            }
            @include mobile {
                width: 100%; 
                height: 300px; 
                position: relative; 
                margin-bottom: 20px;
            }
            @include small-mobile {
                width: 100%; 
                height: 300px; 
                position: relative;
                margin-bottom: 20px;
            }
        } 
        .text {
            max-width: 45%;
            position: relative;
            
            @include tablet {
                max-width: 100%;
            }
            @include mobile {
                max-width: 100%;
            }
            @include small-mobile {
                max-width: 100%;
            }
        }
        .splitView {
            position: relative;
            
            &.top {
                .text {
                    left: 0;
                    padding: 50px 0;
                    
                    @include small-desktop {
                        padding: 40px 0;
                    }
                    @include tablet {
                        padding: 30px;
                    }
                    @include mobile {
                        padding: 30px;
                    }
                    @include small-mobile {
                        padding: 20px;
                    }
                }
                .image {
                    right: 0;
                    top: 0;
                    bottom: 0;
                }
            }
            &.bottom {
                .text {
                    padding: 50px 0;
                    left: 55%;
                    
                    @include small-desktop {
                        padding: 40px 0;
                    }
                    @include tablet {
                        padding: 30px;
                        left: 0;
                    }
                    @include mobile {
                        padding: 30px;
                        left: 0;
                    }
                    @include small-mobile {
                        padding: 20px;
                        left: 0;
                    }
                }
                .image {
                    left: 0;
                    top: 0;
                    bottom: 0;
                }
            }
        }
    }

    #video {
        .intrinsic-container {
            position: relative;
            height: 0;
            overflow: hidden;
            iframe {
                position: absolute;
                top:0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
        .intrinsic-container-16x9 {
                padding-bottom: 56%;
        }
    } 
    
}