#news {
    background-color: $medBlue; 
    #featured {
        .newsCard {
            background-color: $white;     
            width: 100%;   
            height: 390px; 
            margin-bottom: 30px;
            .featureImage {   
                height: 100%;   
                width: 50%; 
                float: left; 
            }
            .content {
                padding: 46px; 
                width: 50%;
                float: right;
            }
        }
    }
    .grid {
        margin-bottom: 90px;
        .newsCard { 
            background-color: $white;     
            width: 100%; 
            margin-bottom: 30px; 
            
            @include mobile {
                margin-bottom: 20px;
            }
            @include small-mobile {
                margin-bottom: 20px;
            }
        }
    } 

}