#pointsSystem {
    h3 {
        font-size: 35px; 
        font-weight: 600;
        margin-top: 8px;
        margin-bottom: 15px;
    }
    ul {
        border: 1px solid $greyC;
        padding-left: 30px; 
        padding-right: 30px;
        padding-bottom: 30px;   
        padding-bottom: 15px;
        padding-top: 15px;
        li {
            border-bottom: 1px solid $greyD;
            padding-top: 8px;
            padding-bottom: 8px;
            h6 {
                display: inline; 
                color: $grey6; 
                font-size: 23px; 
                font-family: $secondary-font; 
                font-weight: 300;
            }
            h5 {
                display: inline; 
                position: absolute;
                right: 0; 
                color: $grey6; 
                font-size: 23px; 
                font-family: $secondary-font; 
                font-weight: 300;
                margin-right: 47px;
            }
        }
        li:last-of-type {
            border-bottom: none;
        }
    }
}