.form {
    background-color: $greyE; 
    padding: 80px 0; 
    max-width: 100%;
    overflow-x: hidden;
    label {    
        font-size: 18px; 
        font-family: $primary-font;   
        color: $grey6; 
        text-transform: uppercase;  
        font-weight: 400;     
        display: block;
        margin: 30px 0 10px 0;
    }

    // Dropdown
    .nice-select {
        border-radius: 25px;  
        height: 50px; 
        font-family: $secondary-font; 
        font-weight: 300;
        color: $grey6; 
        padding-left: 26px;
        line-height: 48px;
        width: 100%;
        margin-bottom: 20px;
        border: 2px solid $white;
        outline: none; 
        transition: $transition;
        &:before {
            border: 2px solid $medBlue;
            content: '';
            display: block;
            height: 20px;
            width: 20px;
            margin-top: -9px;
            right: 32px;
            pointer-events: none;
            position: absolute;
            top: 50%;
            transform-origin: 66% 66%;
            transform: rotate(45deg);
            transition: all .15s ease-in-out;
        }
        &:after {
            border-bottom: 2px solid $medBlue;
            border-right: 2px solid $medBlue;
            height: 6px;
            width: 6px; 
            margin-top: -4px; 
            right: 36.8px;
        }
        &:hover { 
            border-color: #E5E5E5;
        }
        &:active, &:focus {
            border-color: #DDD;
        }
        li {
            margin-bottom: -23px;
        }
        .list {
            background-color: $medBlue; 
            color: $white; 
            margin-left: 31px;
            margin-top: 0px;
            border-radius: 0px;
            
            @include small-mobile {
                margin-left: 18px;
            }
        }
        &.open .list {
            width: 90%;
            padding-bottom: 28px;
        }
        .option.selected {
            font-weight: 200;
            color: $grey9;
        }
        .option.focus, .option.selected.focus, .option:hover {
            background-color: transparent;
        }
        .option:hover {
            color: $yellow;
        }
    }

    textarea {
        font-family: $secondary-font;
        border: 2px solid $white;
        border-radius: 25px;  
        color: $grey6;  
        font-weight: 300;  
        font-size: 18px; 
        padding: 25px 25px;
        height: 300px;
        width: 100%; 
        margin-bottom: 20px;
        resize: none;     
        outline: none;
        transition: $transition;
        
        &:hover { 
            border-color: #E5E5E5;
        }
        &:active, &:focus {
            border-color: #DDD;
        }
    } 

}

// Hides the honeypot field for forms. 
#courseRecord { 
    display: none !important; 
}
.formContent {
    .fui-label {
        display: none;
    }
    .fui-alert-success {
        display: none;
    }
    button{
        margin-top: 30px
    }

}
