#bannerSection {
    width: 100%;
    height: 480px;
    text-align: center;
    @extend .backgroundSettings; 
    position: relative;
    background-color: #F5F5F5;
    
    @include small-desktop {
        height: 450px;
    }
    @include tablet {
        height: 480px;
    }
    @include mobile {
        height: 400px;
    }
    @include small-mobile {
        height: 400px;
    }
    
    &.small {
        height: 300px;
    }

    #titleBanner { 
        margin-top: 167px;
        img {
            height: 75px;
        }
        h1 {
            font-size: 36px;
            color: $white;   
            position: relative;
            top: -65px;
            text-transform: uppercase; 
            font-weight: 600;
            letter-spacing: .06em;
            
            @include mobile {
                font-size: 32px;
                top: -63px;
            }
            @include small-mobile {
                font-size: 25px;
                top: -58px;
            }
        }
    }
}