#intro {
    padding: 85px 0;  
    margin: 0 auto;
    text-align: center;  

    h1 {
        margin-bottom: 30px; 
        line-height: 1.1em;
    } 
    h2 {
        margin: 0 auto 35px auto;
        color: $grey6; 
        font-family: $primary-font; 
        font-size: 36px; 
        font-weight: 300; 
        max-width: 65%;
        
        @include mobile {
            max-width: 80%;
        }
        @include small-mobile {
            max-width: 95%;
        }
    }
    h3 {
        color: $grey6; 
        font-family: $primary-font;
        font-size: 26px;       
        font-weight: 300; 
        margin: 0 auto 35px auto;
        max-width: 60%;
        
        @include mobile {
            max-width: 80%;
        }
        @include small-mobile {
            max-width: 95%;
        }
    } 
} 