#newsLanding {

    #bannerSection {
        img { 
            width: 45px; 
            position: absolute;
            right: 43px;
            bottom: -22px;  
            cursor: pointer; 
            border-radius: 50%;
            border: 3px solid $white;
        }
    }   
    #titleSection {
        padding-top: 55px; 
        padding-bottom: 30px; 
        h1, h5 {
            text-align: left;  
        }
        h1 {
            margin-bottom: 15px;
            line-height: 1.1em;
        }
        h5 {
            text-transform: uppercase;
            letter-spacing: .08em;
            color: $grey9;
            font-size: 18px;
            transition: $transition;
            
            span {
                color: $medBlue;
                margin: 0 8px;
                position: relative;
                top: -2px;
                font-weight: 600;
            }
            a {
                color: $grey9;
                
                &:hover {
                    color: $grey6;
                }
            }
        }
    } 
    .subheadline {
        margin: 30px auto 0 auto;
        h3 {
            font-size: 28px;
            font-weight: 500;
            margin-bottom: 10px;
        }
    }
    .postBody {
        padding-bottom: 40px;
        p {
            margin: 20px 0;
        }
        hr {
            margin: 40px 0;
        }
        ul {
            margin: 0;
            max-width: 50%;
            
            @include tablet {
                max-width: 70%;
            }
            @include mobile {
                max-width: 80%;
            }
            @include small-mobile {
                max-width: 90%;
            }
            li {
                font-family: $secondary-font; 
                color: $grey6; 
                font-size: 18px; 
                border-bottom: 1px solid $greyD; 
                padding: 15px 0px; 
            }
            li:last-child {
                border-bottom: none; 
            }
        }
    }
    .tableMaker {
        margin: 30px 0 50px 0;
    }
    .image {
        padding-top: 30px; 
        padding-bottom: 30px; 
    }
    .callout {
        @extend #volunteer;
        padding: 100px 0px; 
        margin: 50px 0px; 
        h2 {
            max-width: 60%;
            line-height: 1.1em;
            margin: 30px auto 42px auto; 
            
            @include tablet {
                max-width: 70%;
            }
            @include mobile {
                max-width: 80%;
            }
            @include small-mobile {
                max-width: 90%;
            }
        }
        img {
            max-width: 75px;
        }
        .button {
            display: block;
            width: 300px;
        }
    }
    .pullQuote {
        background-color: $tableGrey; 
        position: relative;
        margin: 70px 0 80px 0; 
        padding: 75px 0; 
        width: 100%; 
        @include mobile {
            padding: 40px 0; 
        }
        @include small-mobile { 
            padding: 40px 0; 
        }
        .pointer {
            background-color: $tableGrey;
            height: 36px; 
            width: 36px;  
            transform: rotate(45deg);
            position: absolute;
            top: -80px;
            left: 50%;
            
            @include mobile {
                top: -45px;
            }
            @include small-mobile { 
                top: -45px;
            }
        }
        .content {
            margin: 0 auto;
            max-width: 60%;
            text-align: center;
            
            @include tablet {
                max-width: 75%
            }
            @include mobile {
                max-width: 85%
            }
            @include small-mobile {
                max-width: 90%
            }
            h4 {
                color: $grey9; 
                font-size: 30px;
                font-family: $secondary-font; 
                font-weight: 300;
                line-height: 1.2em;
            }
            h5 {
                color: $grey6;
                font-size: 18px;
                font-family: $secondary-font; 
                font-weight: 400;
                font-style: italic;
                margin-top: 8px; 
            }
        }
    }
    .gallery {
        margin: 50px 0px; 
        .carousel {
            margin-bottom: 20px;
        }
        .flickity-page-dots {
            display: none; 
        }
    }
    .video {
        margin: 50px 0px; 
        .intrinsic-container {
            position: relative;
            height: 0;
            overflow: hidden;
            iframe {
                position: absolute;
                top:0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
        .intrinsic-container-16x9 {
                padding-bottom: 50%;
        }
    }  
    .actionButton {
        margin: 40px 0!important;
        a {
            font-size: 18px; 
        }
    }
    #subscribe {
        margin-top: 80px;
    }
}     