#seriesAwards {

    #description {
        @extend #intro; 
        text-align: left; 
        padding-bottom: 0px;
        padding-left: 0px;
        padding-right: 0px; 
    } 

    #ageGroups {
        @extend #intro; 
        text-align: left; 
        padding-bottom: 0px;
        padding-left: 0px;
        padding-right: 0px; 
        .body {
            margin-bottom: 44px; 
        }
        h4 {
            background-color: $greyE; 
            width: 100%; 
            padding: 22px 0px;  
            text-align: center; 
            margin-bottom: 30px; 
        }
    }

    #ageGraded {
        @extend #intro; 
        padding-top: 40px;
        text-align: left; 
        padding-bottom: 156px; 
        padding-left: 0px;
        padding-right: 0px; 
    }

}