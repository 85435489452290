/* BASE */
@import 'base/_variables.scss';
@import 'base/_mixins.scss';
@import 'modules/_bootstrap.scss'; // THIS MODULE IS HERE SPECIFICALLY BECAUSE IT NEEDS TO BE LOADED BEFORE _base.scss in order for .noPad class to work
@import 'base/_base.scss';
@import 'base/_typography.scss';
@import 'base/_buttons.scss';
@import 'base/_inputs.scss';
@import 'base/_forms.scss';
@import 'base/_validations.scss';


/* MODULES */ 
@import 'modules/flickity.css';
@import 'modules/animate.min.css';
@import 'modules/hover.css';
@import 'modules/hamburgers/hamburgers.scss';
@import 'modules/collapse.scss'; 
@import 'modules/nice-select.scss'; 

/* LAYOUTS */
@import 'layouts/_footer.scss';
@import 'layouts/_nav.scss';
@import 'layouts/_banner.scss';
@import 'layouts/_error.scss';
@import 'layouts/_socialIcons.scss'; 
@import 'layouts/_menuItems.scss';
@import 'layouts/_sponsorsBlock.scss';  
@import 'layouts/_volunteer.scss';
@import 'layouts/_subscribe.scss';
@import 'layouts/_newsCard.scss';
@import 'layouts/_registerStatus.scss';
@import 'layouts/_registerCTA.scss';
@import 'layouts/_tables.scss'; 
@import 'layouts/_pointsSystem.scss'; 
@import 'layouts/_seriesNav.scss'; 
@import 'layouts/_racesLandingNav.scss'; 
@import 'layouts/_modals.scss'; 
@import 'layouts/_gallery.scss'; 
@import 'layouts/_raceCard.scss'; 
@import 'layouts/_intro.scss'; 
@import 'layouts/_bannerButton.scss'; 
@import 'layouts/_alert.scss'; 
@import 'layouts/_exploreNav.scss'; 

/* PAGES */
@import 'pages/_home.scss';
@import 'pages/_contact.scss';
@import 'pages/_news.scss';
@import 'pages/_races.scss';
@import 'pages/_raceLanding.scss';
@import 'pages/_seriesHistory.scss';
@import 'pages/_sponsors.scss';
@import 'pages/_register.scss';
@import 'pages/_opportunities'; 
@import 'pages/_newsLanding.scss'; 
@import 'pages/_standings.scss'; 
@import 'pages/_rules.scss'; 
@import 'pages/_awards.scss'; 
@import 'pages/_seriesVolunteer.scss'; 
@import 'pages/_standingsLanding.scss'; 
@import 'pages/_gallery.scss'; 