#registerStatus {
    background-color: $darkBlue; 
    padding: 10px 22px 10px 15px;
    max-width: 290px;
    
    &:hover {
        .light {
            border-radius: 5%;
        }
    }
    .light {
        width: 12px; 
        height: 12px;   
        border-radius: 50%; 
        display: inline-block;
        margin-bottom: 3px;
        transition: $transition;
        &#open {
            background-color: $green; 
        }
        &#closed {
            background-color: $red; 
        }
    }
    h4 {
        color: $white; 
        display: inline; 
        font-weight: 400;
        margin-left: 10px;
    }
    h5 {
        font-size: 25px;
        font-weight: 600;
        color: $white; 
        display: inline; 
        margin-left: 5px;
    }
}