#home {

    #primarySection {
        background-color: $medBlue; 
        height: 780px;  
        @include tablet { 
            height: auto;   
        } 
        @include mobile { 
            height: auto;   
        }
        @include small-mobile { 
            height: auto;  
        }
        #gallery { 
            #registerStatus {
                position: absolute;
                top: 0px;
                z-index: 989;
            } 
            .carousel {   
                .carousel-cell {      
                    height: 481px; 
                    width: 100%;
                    background-size: cover;
                    background-position: center; 
                    background-repeat: no-repeat; 
                    counter-increment: gallery-cell;
                    
                    @include tablet {
                        height: 400px;
                    }
                    @include mobile {
                        height: 350px;
                    }
                    @include small-mobile {
                        height: 300px;
                    }
                }

                // FADE EFFECT BEGIN 
                .carousel-cell {
                    left: 0 !important; 
                    opacity: 0;
                    transition: opacity 0.9s ease-in-out;
                    z-index: -1;
                }
                .carousel-cell.is-selected {
                    opacity: 1;
                    z-index: 0
                }
                .flickity-slider {
                    transform: none !important;
                }
                // FADE EFFECT END

                .flickity-page-dots {
                    bottom: 20px; 
                    left: 20px;
                    text-align: left; 
                    width: auto!important;
                    .dot {
                        border-radius: 50%; 
                        width: 14px; 
                        height: 14px; 
                        margin: 0 5px;
                        background: rgba(255, 255, 255, 0.4); 
                        opacity: 1; 
                        transition: $transition;
                        
                        &:hover {
                            background: rgba(255, 255, 255, 0.7); 
                        }
                        
                        @include small-mobile {
                            width: 10px; 
                            height: 10px; 
                            margin: 0 4px;
                        }
                    }
                    .is-selected {
                        background: $white; 
                    }
                }
                .flickity-button {
                    display: none; 
                }
                .flickity-prev-next-button {
                    display: none; 
                }
                .carousel-cell:before {
                    display: none;   
                } 
            }
        }
        #primaryCTA {
            background-color: $darkBlue; 
            margin-top: -197px;
            padding-top: 30px;
            padding-bottom: 60px; 
            padding-left: 20px;
            padding-right: 20px;
            text-align: center;
            @include tablet { 
                margin-top: 46px; 
                margin-bottom: 46px; 
            } 
            @include mobile { 
                margin-top: 46px;  
                margin-bottom: 46px; 
            }
            @include small-mobile { 
                margin-top: 30px; 
                margin-bottom: 30px;  
            }
            h3 {
                color: $white; 
                font-size: 35px; 
                line-height: 1.2em;
                margin-bottom: 40px;
            }
        }
        #upcomingRaces {
            background: $white; 
            height: 714px;
            padding: 0px 20px;
            overflow-y: scroll; 
            
            @include tablet { 
                margin-top: 46px; 
                overflow-y: visible; 
                height: auto; 
            } 
            @include mobile { 
                margin-top: 46px;  
                overflow-y: visible; 
                height: auto; 
            }
            @include small-mobile { 
                margin-top: 30px;  
                overflow-y: visible; 
                height: auto; 
            }
            li {
                border-bottom: 2px solid $greyE;
                padding: 25px 0px; 
                
                &:hover {
                    h2 {
                        opacity: .6;
                    }
                }
                .titleBar {
                    h1 {
                        display: inline; 
                        font-weight: 600;
                        
                        @include small-mobile {
                            font-size: 35px;
                        }
                    }
                    img {
                        width: 28px; 
                        margin-left: 10px;
                        margin-bottom: 15px;
                        transition: $transition;
                    }
                    &:hover {
                        img {
                            transform: translateX(5px);
                        }
                    }
                }
                h2 {
                    font-size: 25px;
                    font-family: $secondary-font;
                    font-weight: 600;
                    transition: $transition;
                }
                p {
                    font-weight: 300;
                    font-size: 22px;
                    
                    span {
                        color: $yellow;
                        margin: 0 5px;
                        position: relative;
                        top: -1px;
                        font-weight: 500;
                    }
                }
            }
        }
    }   

    #intro {
        padding-top: 100px 0; 
    }

    #newsPreview {
        margin-bottom: 70px; 
    }

}