.gallery {
    .carousel {
        margin-bottom: 40px; 
    }
    .carousel-main {
        // FADE EFFECT BEGIN
        .carousel-cell {
            left: 0 !important; 
            opacity: 0;
            transition: opacity 0.9s ease-in-out;
            z-index: -1;
        }
        .carousel-cell.is-selected {
            opacity: 1;
            z-index: 0
        }
        .flickity-slider {
            transform: none !important;
        }
        // FADE EFFECT END
    }
    .carousel-cell {  
        width: 100%;
        height: 515px;
        margin-right: 10px; 
        background-size: contain;
        background-position: center; 
        background-repeat: no-repeat; 
        counter-increment: carousel-cell;
        
        @include tablet {
            height: 450px;
        }
        @include mobile {
            height: 400px;
        }
        @include small-mobile {
            height: 350px;
        }
        
        &.thumb {
            background-size: cover;
        }
    }
    /* cell number */
    .carousel-cell:before {
        display: none; 
        text-align: center;
        line-height: 200px;
        font-size: 80px; 
    }
    .carousel-nav .carousel-cell {
        height: 80px;
        width: 100px;  
        transition: $transition;
        border: 1px solid $medBlue;
        
        &:hover {
            border: 1px solid $yellow;
        }
    }
    .carousel-nav .carousel-cell:before {
        font-size: 50px;
        line-height: 80px;
    }
    .flickity-button {
        display: none; 
    }
    .flickity-prev-next-button {
        display: none; 
    }
    .carousel-cell:before {
        display: none;
    }
    .flickity-page-dots {
        .dot {
            height: 8px;
            width: 30%;
            margin: 0 5px!important;
            border-radius: 0;
        }
    }
}