#footer {
    #footerContent {
        background-color: $medBlue; 
        text-align: center;
        padding: 64px 0px; 
            .navLogo {
                width: 120px; 
                margin-bottom: 20px;
                transition: $transition;
                &:hover {
                    transform: rotate(-5deg);
                }
            }
            h5 {
                transition: $transition;
                &:hover { 
                    opacity: .4;
                    color: $white!important;
                }
            }
        }
        .menuItems {
            margin: 0 auto; 
            li {
                display: inline-block; 
                margin: 0 10px; 
            }
        }  
    #copyright { 
        padding: 24px 0px; 
        background-color: $darkBlue; 
        p, a {
            color: $white; 
            font-size: 16px; 
            font-weight: 300; 
            font-family: $secondary-font; 
        }
        a {
            &:hover {
                color: $yellow;
            }
        }
        p {
            float: left; 
            margin-top: 4px; 
            @include mobile { 
                text-align: center;
                float: none;
            }
            @include small-mobile { 
                text-align: center;
                float: none;
            }
        }
        .socialIcons {
            float: right;
            @include mobile { 
                display: none; 
            }
            @include small-mobile { 
                display: none; 
            }
        }
    }
}