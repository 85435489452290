#seriesVolunteer {

    #description {
        @extend #intro; 
        padding-bottom: 100px;
        padding-left: 0px;
        padding-right: 0px; 
        .body {
            margin-bottom: 60px; 
            
            p {
                max-width: 75%;
                margin: 0 auto;
                
                @include tablet {
                    max-width: 80%;
                }
                @include mobile {
                    max-width: 90%;
                }
                @include small-mobile {
                    max-width: 100%;
                }
            }
        }
    }
    
    .button {
        &:hover {
            background: $medBlue;
            border-color: $medBlue;
        }
    }

}