/*---------------
  FONTS
---------------*/     
$primary-font: "brandon-grotesque", sans-serif;
//$secondary-font: "proxima-nova", sans-serif;       
$secondary-font: "Source Sans Pro", sans-serif;       
       
/*---------------                        
  COLORS     
---------------*/                
$medBlue: #021E50;    
$darkBlue: #051738; 
$yellow: #FDB72B; 
//$medBlue: #1A4A6F;    
//$darkBlue: #14354E; 
//$yellow: #E7C332; 
$green: #88E87B;     
$red: #EA4C4C; 
$white: #FFF;
$black: #000;
$grey6: #666; 
$grey9: #999; 
$greyC: #CCC;
$greyD: #DDD;
$greyE: #EEE; 
$tableGrey: #F5F5F5;
 
/*---------------
  MEDIA QUERIES
---------------*/
$mobile-width: 480px;
$tablet-width: 768px;
$small-desktop-width: 992px;
$desktop-width: 1200px;
$large-desktop-width: 1440px;

/*---------------
  GENERAL
---------------*/
$shadow: 1px 1px 5px 0px rgba(0,0,0,0.1);
$transition: all .5s ease-in-out;