#sponsorOpportunities {

    #header {
        height: 500px; 
        background-color: $darkBlue; 
        position: relative; 
        #text {
            height: 500px; 
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -ms-flex-align: center;
            -webkit-align-items: center;
            -webkit-box-align: center;
            align-items: center;
            h1, p {
                color: $white; 
                margin-bottom: 30px;
                position: relative; 
                max-width: 100%; 
                padding-right: 30px;
                
                @include mobile {
                    padding-right: 0;
                }
                @include small-mobile {
                    padding-right: 0;
                }
            }
            a {
                color: $yellow; 
                margin-top: 30px;
                
                &:hover {
                    color: $white;
                    opacity: .4;
                }
            }
        }
    }

    .featureImage {
        position: absolute; 
        right: 0px; 
        top: 0px; 
        height: 500px;
        width: 40%; 
        background-size: cover;
        background-repeat: no-repeat; 
        background-position: center;  
        @include mobile {
            position: relative; 
            width: 100% !important; 
            height: 300px !important;
        }
        @include small-mobile {
            position: relative; 
            width: 100% !important; 
            height: 300px !important; 
        }
    }

    #sponsorForm {
        h2 {
            text-align: center; 
            margin-bottom: 50px; 
            font-size: 40px; 
            font-weight: 600;
            line-height: 1.2em;
            
            @include tablet {
                margin-top: 60px;
            }
            @include mobile {
                margin-top: 60px;
                margin-top: 300px;
            }
            @include small-mobile {
                margin-top: 60px; 
                margin-top: 300px;
            }
        }
        #body { 
            padding: 0px 20px; 
            margin-bottom: 70px; 
            
            p {
                text-align: center;
                width: 75%;
                margin: 0 auto;
                
                @include mobile {
                    width: 85%;
                }
                @include small-mobile {
                    width: 95%;
                }
            }
        }
        .formie-turnstile-placeholder, .fui-btn-wrapper {
            max-width: 68%;
            margin: 0 auto;
            @include mobile {
                max-width: 100%;
            }
            @include small-mobile {
                max-width: 100%;
            }
        }
        form {
            padding-bottom: 0px; 
            label {
                display: none;
            }
            legend {
                display: none;
            }
            textarea {
                display: none;   
            }
            .fui-fieldset {
                border: none;
                margin: 0;
                padding: 0;
                .fui-field {
                    margin: 0 auto;
                    width: 100% !important;
                }
            }
            .fui-page-row {
                display: flex;
                .fui-field {
                    margin: 0 auto;
                    width: 68%;
                    @include mobile {
                        width: 100%; 
                    }
                    @include small-mobile {
                        width: 100%; 
                    }
                }
                .fui-type-address {
                    
                    .fui-row {
                        display: block;
                    }
                }
            }
            input, .nice-select {
                display: inline-block; 
                width: 98%;
                @include mobile {
                    width: 100%; 
                }
                @include small-mobile {
                    width: 100%; 
                }
            }
            #fromName, #fromEmail, #streetAddress, #zipCode, .nice-select {
                margin-right: 22px; 
            }
            button {
                margin-top: 30px;
                &:hover {
                    background: $darkBlue;
                    border-color: $darkBlue;
                }
            }
        }
        #formValidationMessages {
            margin: 0 auto;
            width: 68%;
            @include mobile {
                width: 100%; 
            }
            @include small-mobile {
                width: 100%; 
            }
        }
    }

}