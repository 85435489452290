h1,h2,h3,h4,h5,h6,p {
    margin: 0; 
    padding: 0; 
}
h1 {       
    font-family: $primary-font;       
  	font-size: 40px;
  	color: $medBlue;         
  	font-weight: 600;
}   
h2 {
    font-family: $primary-font; 
    font-size: 35px;
    color: $medBlue;
    font-weight: 500; 
} 
h3 {
    font-family: $primary-font; 
    font-size: 30px;
    color: $medBlue;
    font-weight: 400; 
}
h4 {
    font-family: $primary-font;
    color: $medBlue;
    font-size: 25px;
    font-weight: 500;
}
h5 {
    font-family: $primary-font;
    color: $medBlue;
	font-size: 20px;   
	font-weight: 500;
}
h6 {
    font-family: $primary-font;   
    font-size: 18px;
    font-weight: 400;
}
p {
    font-family: $secondary-font;
    color: $grey6;
    font-size: 25px;
    font-weight: 300;
    line-height: 1.4em;
    
    a {
        overflow-wrap: break-word;
        &:hover {
            color: $yellow;
        }
    }
    @include small-mobile {
        font-size: 22px;
    }
}
b {
    font-weight: 500;
}
strong {
    font-weight: 500;
}
a:link {
    color: $medBlue;
}
a:visited {
    color: $medBlue;
}
a:hover {
    color: $darkBlue;
}
a:active {
    color: $medBlue;
}
